<template>
  <v-container>
    <v-row class="my-0">
      <v-col cols="12" md="3">
      </v-col>
      <v-col cols="12" md="3">
      </v-col>
      <v-col cols="12" md="3">
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      requesting: false,
      operating: false,
      querying: false,

      phoneEdit: null,
      phoneSearch: null,
      query: {page: 1, size: 10, type: this.type, status: ''},
      list: [],
      total: 0,
      addDialog: false,
      editDialog: false,
      delDialog: false,
      loginDialog: false,
      item: {},
      phoneList: [],
      status: 0,
      captcha: '',
      interval: null
    }
  },
  watch: {

  },
  mounted() {
  },
  methods: {


    copy(text) {
      // 创建输入框元素
      let oInput = document.createElement('input')
      // 将想要复制的值
      oInput.value = text
      // 页面底部追加输入框
      document.body.appendChild(oInput)
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy')
      // 弹出复制成功信息
      this.$store.commit('toast', '复制成功')
      // 复制后移除输入框
      oInput.remove()
    },
  }
}
</script>

<style scoped>

</style>